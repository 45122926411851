define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration", ["exports", "@ember/object", "@ember/service", "discourse/routes/discourse"], function (_exports, _object, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegration extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    model() {
      return this.store.findAll("provider");
    }
    showSettings() {
      this.router.transitionTo("adminSiteSettingsCategory", "chat_integration");
    }
    static #_2 = (() => dt7948.n(this.prototype, "showSettings", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegration;
});