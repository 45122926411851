define("discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="admin-plugin-chat">
    <div class="admin-controls">
      <div class="admin-controls-chat-providers">
        <ul class="nav nav-pills">
          {{#each this.model as |provider|}}
            <NavItem
              @route="adminPlugins.chat-integration.provider"
              @routeParam={{provider.name}}
              @label={{concat
                "chat_integration.provider."
                provider.name
                ".title"
              }}
            />
          {{/each}}
        </ul>
      </div>
  
      <DButton
        @icon="gear"
        @title="chat_integration.settings"
        @label="chat_integration.settings"
        @action={{route-action "showSettings"}}
        class="chat-integration-settings-button"
      />
    </div>
  
    {{#unless this.model.totalRows}}
      {{i18n "chat_integration.no_providers"}}
    {{/unless}}
  
    {{outlet}}
  </div>
  */
  {
    "id": "UJHgcALI",
    "block": "[[[10,0],[14,1,\"admin-plugin-chat\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"admin-controls\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"admin-controls-chat-providers\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"          \"],[8,[39,4],null,[[\"@route\",\"@routeParam\",\"@label\"],[\"adminPlugins.chat-integration.provider\",[30,1,[\"name\"]],[28,[37,5],[\"chat_integration.provider.\",[30,1,[\"name\"]],\".title\"],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,6],[[24,0,\"chat-integration-settings-button\"]],[[\"@icon\",\"@title\",\"@label\",\"@action\"],[\"gear\",\"chat_integration.settings\",\"chat_integration.settings\",[28,[37,7],[\"showSettings\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"model\",\"totalRows\"]]],[[[1,\"    \"],[1,[28,[35,9],[\"chat_integration.no_providers\"],null]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,[28,[37,11],null,null],null,null,null],[1,\"\\n\"],[13]],[\"provider\"],false,[\"div\",\"ul\",\"each\",\"-track-array\",\"nav-item\",\"concat\",\"d-button\",\"route-action\",\"unless\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration.hbs",
    "isStrictMode": false
  });
});