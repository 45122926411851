define("discourse/plugins/discourse-chat-integration/admin/components/rule-row", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    <td>
      {{@rule.filterName}}
    </td>
  
    <td>
      {{#if this.isCategory}}
        {{#if @rule.category}}
          {{category-link @rule.category allowUncategorized="true" link="false"}}
        {{else}}
          {{i18n "chat_integration.all_categories"}}
        {{/if}}
      {{else if this.isMention}}
        {{i18n "chat_integration.group_mention_template" name=@rule.group_name}}
      {{else if this.isMessage}}
        {{i18n "chat_integration.group_message_template" name=@rule.group_name}}
      {{/if}}
    </td>
  
    <td>
      {{#if this.siteSettings.tagging_enabled}}
        {{#if @rule.tags}}
          {{@rule.tags}}
        {{else}}
          {{i18n "chat_integration.all_tags"}}
        {{/if}}
      {{/if}}
    </td>
  
    <td>
      <DButton
        @icon="pencil"
        @title="chat_integration.rule_table.edit_rule"
        @action={{fn @edit @rule}}
        class="edit"
      />
  
      <DButton
        @icon="far-trash-can"
        @title="chat_integration.rule_table.delete_rule"
        @action={{fn this.delete @rule}}
        class="delete"
      />
    </td>
  </tr>
  */
  {
    "id": "nHC/irUn",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"filterName\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCategory\"]],[[[41,[30,1,[\"category\"]],[[[1,\"        \"],[1,[28,[35,3],[[30,1,[\"category\"]]],[[\"allowUncategorized\",\"link\"],[\"true\",\"false\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],[\"chat_integration.all_categories\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"isMention\"]],[[[1,\"      \"],[1,[28,[35,4],[\"chat_integration.group_mention_template\"],[[\"name\"],[[30,1,[\"group_name\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isMessage\"]],[[[1,\"      \"],[1,[28,[35,4],[\"chat_integration.group_message_template\"],[[\"name\"],[[30,1,[\"group_name\"]]]]]],[1,\"\\n    \"]],[]],null]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[41,[30,1,[\"tags\"]],[[[1,\"        \"],[1,[30,1,[\"tags\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],[\"chat_integration.all_tags\"],null]],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,5],[[24,0,\"edit\"]],[[\"@icon\",\"@title\",\"@action\"],[\"pencil\",\"chat_integration.rule_table.edit_rule\",[28,[37,6],[[30,2],[30,1]],null]]],null],[1,\"\\n\\n    \"],[8,[39,5],[[24,0,\"delete\"]],[[\"@icon\",\"@title\",\"@action\"],[\"far-trash-can\",\"chat_integration.rule_table.delete_rule\",[28,[37,6],[[30,0,[\"delete\"]],[30,1]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@rule\",\"@edit\"],false,[\"tr\",\"td\",\"if\",\"category-link\",\"i18n\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/rule-row.hbs",
    "isStrictMode": false
  });
  class RuleRow extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get isCategory() {
      return this.args.rule.type === "normal";
    }
    get isMessage() {
      return this.args.rule.type === "group_message";
    }
    get isMention() {
      return this.args.rule.type === "group_mention";
    }
    delete(rule) {
      rule.destroyRecord().then(() => this.args.refresh()).catch(_ajaxError.popupAjaxError);
    }
    static #_2 = (() => dt7948.n(this.prototype, "delete", [_object.action]))();
  }
  _exports.default = RuleRow;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RuleRow);
});