define("discourse/plugins/discourse-chat-integration/admin/components/channel-data", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @provider.channel_parameters as |param|}}
    {{#unless param.hidden}}
      <div class="channel-info">
        <span class="field-name">
          {{i18n
            (concat
              "chat_integration.provider."
              @channel.provider
              ".param."
              param.key
              ".title"
            )
          }}:
        </span>
        <span class="field-value">{{get @channel.data param.key}}</span>
        <br />
      </div>
    {{/unless}}
  {{/each}}
  */
  {
    "id": "UUP3ulkO",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"channel_parameters\"]]],null]],null],null,[[[41,[51,[30,2,[\"hidden\"]]],[[[1,\"    \"],[10,0],[14,0,\"channel-info\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"field-name\"],[12],[1,\"\\n        \"],[1,[28,[35,5],[[28,[37,6],[\"chat_integration.provider.\",[30,3,[\"provider\"]],\".param.\",[30,2,[\"key\"]],\".title\"],null]],null]],[1,\":\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"field-value\"],[12],[1,[28,[35,7],[[30,3,[\"data\"]],[30,2,[\"key\"]]],null]],[13],[1,\"\\n      \"],[10,\"br\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[2]],null]],[\"@provider\",\"param\",\"@channel\"],false,[\"each\",\"-track-array\",\"unless\",\"div\",\"span\",\"i18n\",\"concat\",\"get\",\"br\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/channel-data.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "channel-data"));
});