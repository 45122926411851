define("discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration-provider", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.anyErrors}}
    <div class="error">
      {{d-icon "triangle-exclamation"}}
      <span class="error-message">
        {{i18n "chat_integration.channels_with_errors"}}
      </span>
    </div>
  {{/if}}
  
  {{#each this.model.channels.content as |channel|}}
    <ChannelDetails
      @channel={{channel}}
      @provider={{this.model.provider}}
      @refresh={{this.refresh}}
      @editChannel={{this.editChannel}}
      @test={{this.testChannel}}
      @createRule={{this.createRule}}
      @editRuleWithChannel={{this.editRuleWithChannel}}
      @showError={{this.showError}}
    />
  {{/each}}
  
  <div class="table-footer">
    <div class="pull-right">
      <DButton
        @action={{fn this.createChannel this.model.provider}}
        @label="chat_integration.create_channel"
        @icon="plus"
        id="create-channel"
      />
    </div>
  </div>
  */
  {
    "id": "ZMMckW1h",
    "block": "[[[41,[30,0,[\"anyErrors\"]],[[[1,\"  \"],[10,0],[14,0,\"error\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"triangle-exclamation\"],null]],[1,\"\\n    \"],[10,1],[14,0,\"error-message\"],[12],[1,\"\\n      \"],[1,[28,[35,4],[\"chat_integration.channels_with_errors\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"model\",\"channels\",\"content\"]]],null]],null],null,[[[1,\"  \"],[8,[39,7],null,[[\"@channel\",\"@provider\",\"@refresh\",\"@editChannel\",\"@test\",\"@createRule\",\"@editRuleWithChannel\",\"@showError\"],[[30,1],[30,0,[\"model\",\"provider\"]],[30,0,[\"refresh\"]],[30,0,[\"editChannel\"]],[30,0,[\"testChannel\"]],[30,0,[\"createRule\"]],[30,0,[\"editRuleWithChannel\"]],[30,0,[\"showError\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,0],[14,0,\"table-footer\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"pull-right\"],[12],[1,\"\\n    \"],[8,[39,8],[[24,1,\"create-channel\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,9],[[30,0,[\"createChannel\"]],[30,0,[\"model\",\"provider\"]]],null],\"chat_integration.create_channel\",\"plus\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"channel\"],false,[\"if\",\"div\",\"d-icon\",\"span\",\"i18n\",\"each\",\"-track-array\",\"channel-details\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/templates/plugins-chat-integration-provider.hbs",
    "isStrictMode": false
  });
});