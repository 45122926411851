define("discourse/plugins/discourse-chat-integration/admin/controllers/admin-plugins-chat-integration-provider", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/discourse-chat-integration/admin/components/modal/channel-error", "discourse/plugins/discourse-chat-integration/admin/components/modal/edit-channel", "discourse/plugins/discourse-chat-integration/admin/components/modal/edit-rule", "discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration"], function (_exports, _controller, _object, _service, _channelError, _editChannel2, _editRule, _testIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationProvider extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get anyErrors() {
      let anyErrors = false;
      this.model.channels.forEach(channel => {
        if (channel.error_key) {
          anyErrors = true;
        }
      });
      return anyErrors;
    }
    async triggerModal(modal, model) {
      await this.modal.show(modal, {
        model: {
          ...model,
          admin: true
        }
      });
      this.refresh();
    }
    createChannel() {
      return this.triggerModal(_editChannel2.default, {
        channel: this.store.createRecord("channel", {
          provider: this.model.provider.id,
          data: {}
        }),
        provider: this.model.provider
      });
    }
    static #_3 = (() => dt7948.n(this.prototype, "createChannel", [_object.action]))();
    editChannel(channel) {
      return this.triggerModal(_editChannel2.default, {
        channel,
        provider: this.model.provider
      });
    }
    static #_4 = (() => dt7948.n(this.prototype, "editChannel", [_object.action]))();
    testChannel(channel) {
      return this.triggerModal(_testIntegration.default, {
        channel
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "testChannel", [_object.action]))();
    createRule(channel) {
      return this.triggerModal(_editRule.default, {
        rule: this.store.createRecord("rule", {
          channel_id: channel.id,
          channel
        }),
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "createRule", [_object.action]))();
    editRuleWithChannel(rule, channel) {
      return this.triggerModal(_editRule.default, {
        rule,
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "editRuleWithChannel", [_object.action]))();
    showError(channel) {
      return this.triggerModal(_channelError.default, {
        channel
      });
    }
    static #_8 = (() => dt7948.n(this.prototype, "showError", [_object.action]))();
    refresh() {
      this.send("refreshProvider");
    }
    static #_9 = (() => dt7948.n(this.prototype, "refresh", [_object.action]))();
  }
  _exports.default = AdminPluginsChatIntegrationProvider;
});