define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration-index", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsChatIntegrationIndex extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    afterModel(model) {
      if (model.totalRows > 0) {
        this.router.transitionTo("adminPlugins.chat-integration.provider", model.get("firstObject").name);
      }
    }
  }
  _exports.default = AdminPluginsChatIntegrationIndex;
});